@import "../../../variables";
@import "../../../../components/headers";
@import "bootstrap/scss/mixins/breakpoints";

.textbox-component-container {
    a {
        font-weight: $font-weight-huskie;
        text-decoration: underline;
        color: currentColor !important;

        &:hover {
            color: currentColor !important;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline-offset: 2px;
        }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    .h7 {
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(sm) {
        h1,
        .h1,
        h2,
        .h2 {
            @include h3();
        }

        h3,
        .h3 {
            @include h4();
        }
    }

    .faq-subheading {
        font-family: $univers-bold;
        font-size: 14px;
        margin-bottom: 0;
    }
}

.storepage#accessibility_statement,
.storepage#media {
    .textbox-component-container h2 {
        @include h3();
    }
}

.storepage#first-responder-discount,
.storepage#medical-discount,
.storepage#military-discount,
.storepage#teacher-discount,
.storepage#student-discount {
    .textbox-component-container {
        h2 {
            @include h6();

            text-align: center;
        }

        p.top {
            text-align: center;
        }

        h2.med-list {
            text-align: left;
            @include h4();
        }

        h2.note {
            text-align: left;
            margin: 0;
        }
    }
}

.storepage#industry-partner-discount {
    .textbox-component-container {
        h2 {
            @include h4();

            text-align: center;
        }

        p.top {
            text-align: center;
        }

        h2.note {
            @include h6();

            text-align: left;
            margin: 0;
        }
    }
}

#customerservice-warranty {
    .textbox-component-container {
        h2.start-warranty,
        h2.warranty-policy {
            @include h3();
        }

        ul.repair-replace-steps {
            list-style-type: none;
            padding-left: 0;
        }

        h2.warranty-questions {
            @include h4();
        }
    }
}

#corporate-gifting-co-brand {
    .textbox-component-container {
        h2 {
            @include h3();
        }
    }
}

#leatherman-insider-rewards-program {
    h3.benefits,
    h3.how-it-works,
    h3.ways-to-earn {
        @include h5();

        text-transform: uppercase;
    }
}
